import TestimonialImage from "../../../../src/components/Visual/TestimonialImage";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import Question from "../../../../src/svg/question.svg";
import Pitching from "../../../../src/svg/pitching.svg";
import Report from "../../../../src/svg/report.svg";
import Project from "../../../../src/svg/project.svg";
import * as React from 'react';
export default {
  TestimonialImage,
  CustomerTestimonial,
  Question,
  Pitching,
  Report,
  Project,
  React
};