import TestimonialImage from "../../../../src/components/Visual/TestimonialImage";
import CustomerTestimonial from "../../../../src/components/Visual/CustomerTestimonial";
import Data from "../../../../src/svg/data.svg";
import Environment from "../../../../src/svg/environment.svg";
import TermSheet from "../../../../src/svg/termsheet.svg";
import Goals from "../../../../src/svg/goals.svg";
import * as React from 'react';
export default {
  TestimonialImage,
  CustomerTestimonial,
  Data,
  Environment,
  TermSheet,
  Goals,
  React
};